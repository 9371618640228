<template>
  <div>
    <Breadcrumbs main="" title="Notifications Logs" />
    <b-container fluid>
      <b-row class="justify-content-end align-items-center">
        <b-col cols="12" md="auto">
          <multiselect
            size="sm"
            v-model="selectedContactType"
            placeholder="Select Contact Type"
            label="value"
            track-by="key"
            :options="alertTypeArray"
            :multiple="true"
            :taggable="true"
          >
          </multiselect>
        </b-col>

        <b-col cols="12" md="auto" class="mt-2 mt-md-0">
          <b-form-input
            id="filter-input"
            v-model="selectedAlertId"
            type="search"
            placeholder="Alert id for search"
          >
          </b-form-input>
        </b-col>

        <b-col cols="12" md="auto" class="mt-2 mt-md-0">
          <div class="d-flex justify-content-end">
            <b-button variant="primary" class="mr-2" @click="filterLog"
              >Filter</b-button
            >
            <b-button variant="primary" @click="init">Reset</b-button>
          </div>
        </b-col>
      </b-row>

<b-row>
  <b-col md="12" class="m-1">
    <div class="pagination d-flex justify-content-end align-items-center ">
      <div class="m-1 p-1">
    Per Page 
      </div>
     <div>
       <b-form-select
        v-model="perPage"
        :options="pageOptions"
        @change="filterLog"
      ></b-form-select> </div>
    </div>
  </b-col>
</b-row>
      <b-row>
        <b-col>
          <b-table
             stacked="md"
            responsive
            striped
            small
            :items="items"
            :fields="fields"
            class="p-1"
          >
            <!-- Assuming you have an 'id' field -->
            <template #cell(alert_id)="data">
              <span class="res_text">{{ data.item.alert_id }}</span>
            </template>
              <template #cell(message)="data">
              <span class="res_text">{{ data.item.message }}</span>
            </template>
              <template #cell(subject)="data">
              <span class="res_text">{{ data.item.subject }}</span>
            </template>

            <template #cell(contactType)="data">
              <span>{{ alertType[data.item.contactType] }}</span>
            </template>

            <template #cell(success)="data">
              <span v-if="data.item.success" class="text-success">Yes</span>
              <span v-else class="text-danger">
                <b-button
                  variant="danger"
                  size="sm"
                  @click="sendNotification(data.item)"
                >
                  Send Notification
                </b-button>
              </span>
            </template>
          </b-table>
        </b-col>
      </b-row>

      <b-row>
  <b-col md="12" class="m-1">
    <div class="pagination d-flex justify-content-end align-items-center ">
      <a v-show="currentPage != 1" @click="filterLog('prev')" >
        &laquo; Back
      </a>
      <a class="active ">{{ currentPage }}</a>
      <a v-show="items.length >= 1" @click="filterLog('next')">
        Next &raquo;
      </a>
    </div>
  </b-col>
</b-row>
    </b-container>
  </div>
</template>


<script>
import Multiselect from "vue-multiselect";
import ModeratorController from "../../../services/moderatorController";
import ManagerController from "../../../services/managerController";
import { alertType } from "./../../constants/config.js";

export default {
  name: "NotificationsLogs",
  components: {
    Multiselect,
  },
  mounted() {
    this.init();
  },
  methods: {
    rows() {
      return this.items.length;
    },
    init() {
      //get logs
      this.selectedContactType = [];
      this.selectedAlertId = "";
      this.getLogs();
    },
    async filterLog(txt) {
      let payload = {};
      if (txt == "next") {
        this.currentPage++;
      }
      if (txt == "prev") {
        if (this.currentPage != 1) {
          this.currentPage--;
        }
      }
      if (this.selectedContactType && this.selectedContactType.length > 0) {
        payload.aTypes = this.selectedContactType.map((e) => e.key);
      }
      if (this.selectedAlertId) {
        payload.alertId = this.selectedAlertId;
      }

      this.getLogs(payload);
    },
    async sendNotification(item) {
      console.log("sendNotification", item);
      let payload = {
        alertLogId: item._id,
      };
      try {
        let response = await ModeratorController.sendManualNotification(
          payload
        );
        if (response.result) {
          this.getLogs();
          this.$toasted.show(response.message, {
            theme: "outline",
            position: "bottom-center",
            type: "success",
            duration: 2000,
          });
        } else {
          this.$toasted.show(response.message, {
            theme: "outline",
            position: "bottom-center",
            type: "error",
            duration: 2000,
          });
        }
      } catch (e) {
        console.error("error in sendNotification", e);
      }
    },
    async getLogs(payload = {}) {
      payload.page = this.currentPage;
      payload.limit = this.perPage;

      try {
        let response

        if(this.getUserRole() == 'manager'){
          response = await ManagerController.getNotificationLogs(payload)
        } else if(this.getUserRole() == 'moderator'){
          response = await ModeratorController.getNotificationLogs(payload)
        }

        if (response.result) {
          this.items = response.data;
        } else {
        }
      } catch (e) {
        console.error("error in getLogs", e);
      }
    },
    getUserRole(){
      let user  = JSON.parse(window.localStorage.getItem('userinfo'))
      return user.role
    },
  },
  data() {
    return {
      totalPage: 0,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 50],
      alertType,
      selectedContactType: [],
      alertTypeArray: Object.keys(alertType).map((e) => {
        return { key: e, value: alertType[e] };
      }),
      items: [],
      fields: [
        { key: "alert_id", label: "Alert ID" },
        { key: "contactType", label: "Contact Type" },
        { key: "subject", label: "Subject" },
        { key: "message", label: "Message" },

        { key: "createdAt", label: "Created At" },
        { key: "success", label: "Notification Sent" },
      ],
      selectedAlertId: "",
    };
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>

.pagination {
  display: inline-block;
}

.pagination a {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  transition: background-color 0.3s;
  border: 1px solid #ddd;
  font-size: 12px;
}

.pagination a.active {
  background-color: #007bff;
  color: white;
  border: 1px solid #007bff;
}

.pagination a:hover:not(.active) {
  background-color: #ddd;
  cursor: pointer;
}
</style>

    